import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAnchor.figmaUrl.ios} codeUrl={checklists.componentAnchor.codeUrl.ios} checklists={checklists.componentAnchor.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`LGNAnchorTextUIKit provides clickable anchor text that can hyperlink to other sites or pages.
It supports various sizes, icons, and states to fit different design requirements.`}</p>
    <p>{`Legion iOS UIKit offers five themes: `}<inlineCode parentName="p">{`ThemeAGRUIKit`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeEazyUIKit`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeIHSUIKit`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeLGNUIKit`}</inlineCode>{`, and `}<inlineCode parentName="p">{`ThemeMyTEnSUIKit`}</inlineCode>{`.`}</p>
    <p>{`To import a theme, use the following syntax:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeLGNUIKit
`}</code></pre>
    <div className="divi" />
    <h2>{`Examples`}</h2>
    <h3>{`Size Large (.lg)`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/ios-uikit-anchor-size-large.png",
      "alt": "LGNAnchorText Size Large"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let anchorText = LGNAnchorTextUIKit(
    size: .lg,
    textLabel: "Submit",
    targetURL: URL(string: "https://legion.telkom.design")
)
`}</code></pre>
    <h3>{`Size Medium (.md)`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/ios-uikit-anchor-size-medium.png",
      "alt": "LGNAnchorText Size Medium"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let anchorText = LGNAnchorTextUIKit(
    size: .md,
    textLabel: "Submit",
    targetURL: URL(string: "https://legion.telkom.design")
)
`}</code></pre>
    <h3>{`Size Small (.sm)`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/ios-uikit-anchor-size-small.png",
      "alt": "LGNAnchorText Size Small"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let anchorText = LGNAnchorTextUIKit(
    size: .sm,
    textLabel: "Submit",
    targetURL: URL(string: "https://legion.telkom.design")
)
`}</code></pre>
    <h3>{`Left Icon`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/ios-uikit-anchor-left-icon.png",
      "alt": "LGNAnchorText Left Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let anchorText = LGNAnchorTextUIKit(
    textLabel: "Submit",
    leftIcon: UIImage(systemName: "arrow.clockwise.circle.fill"),
    targetURL: URL(string: "https://legion.telkom.design")
)
`}</code></pre>
    <h3>{`Right Icon`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/ios-uikit-anchor-right-icon.png",
      "alt": "LGNAnchorText Right Icon"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let anchorText = LGNAnchorTextUIKit(
    textLabel: "Submit",
    rightIcon: UIImage(systemName: "arrow.right.square.fill"),
    targetURL: URL(string: "https://legion.telkom.design")
)
`}</code></pre>
    <h3>{`State Pressed`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/ios-uikit-anchor-on-pressed.png",
      "alt": "LGNAnchorText Pressed"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let anchorText = LGNAnchorTextUIKit(
    textLabel: "Submit"
)
anchorText.onPressed = {
    guard let url = URL(string: "https://legion.telkom.design") else { return }
    UIApplication.shared.open(url)
}
`}</code></pre>
    <h3>{`State Focused`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/ios-uikit-anchor-on-focussed.png",
      "alt": "LGNAnchorText Focused"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let anchorText = LGNAnchorTextUIKit(
    textLabel: "Submit"
)
anchorText.onFocused = {
    guard let url = URL(string: "https://legion.telkom.design/components/anchor/ios/") else { return }
    UIApplication.shared.open(url)
}
`}</code></pre>
    <h3>{`Disabled`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/ios-uikit-anchor-disabled.png",
      "alt": "LGNAnchorText Disabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let anchorText = LGNAnchorTextUIKit(
    textLabel: "Submit"
)
anchorText.isDisable = true
`}</code></pre>
    <h3>{`Full Configuration`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/ios-uikit-anchor-full-configuration.png",
      "alt": "LGNAnchorText Full Configuration"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let anchorText = LGNAnchorTextUIKit(
    size: .lg,
    textLabel: "Submit",
    leftIcon: UIImage(systemName: "arrow.clockwise.circle.fill"),
    rightIcon: UIImage(systemName: "arrow.right.square.fill"),
    targetURL: URL(string: "https://legion.telkom.design")
)
anchorText.isDisable = false
anchorText.onPressed = {
    print("Anchor is pressed!")
}
anchorText.onFocused = {
    print("Anchor is focused!")
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isDisable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the anchor text is disabled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The icon displayed on the left side of the text.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onFocused`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closure to be executed when the anchor text is focused (long press started).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onPressed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closure to be executed when the anchor text is pressed. Overrides `}<inlineCode parentName="td">{`targetURL`}</inlineCode>{` and will be called instead when tapped.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rightIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The icon displayed on the right side of the text.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the anchor text. Available sizes: `}<inlineCode parentName="td">{`.sm`}</inlineCode>{`, `}<inlineCode parentName="td">{`.md`}</inlineCode>{`, `}<inlineCode parentName="td">{`.lg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.md`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`targetURL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL that the anchor text will link to.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textLabel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The text displayed within the anchor.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      